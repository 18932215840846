<template>
	<div class="chat-list-wrapper">
		<div style="height: 50px;">
			<div class="customer-service">
				<div class="nickname" v-if="loginChatMember">昵称：{{ loginChatMember.nickname }}</div>
				<div class="staff" title="客服人员" @click="chatWithStaff">
					<el-image :src="require('@/assets/staff.png')"></el-image>
				</div>
			</div>
		</div>
		<div class="chat-list">
			<div class="chat-item"
			     :class="{ 'chat-item-selected': interactiveChatMember && item.chat_group_id === interactiveChatMember.chat_group_id && item.chat_member_id === interactiveChatMember.chat_member_id }"
			     v-for="(item, index) in chatList"
			     :key="index"
			     @click="selectChat(index)">
				<div class="avatar">
					<el-image style="width: 48px;height: 48px;border-radius: 5px;border: 1px solid #eee;" :src="item.avatar"></el-image>
				</div>
				<div class="name" :style="{ 'line-height': item.last_message_txt ? '25px' : '50px' }">{{ item.name }}</div>
				<div class="last-message-text">{{ item.last_message_txt }}</div>
				<div class="last-message-time">{{ item.last_message_time_txt }}</div>
				<div class="unread-count" v-if="item.unread_count > 0">{{ item.unread_count }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'ChatList',
  props: {
    chatList: {
      type: Object,
      default: [],
    },
    loginChatMember: {
      type: Object,
      default: null,
    },
    interactiveChatMember: {
      type: Object,
      default: null,
    }
  },
  methods: {
    // 选中聊天
    selectChat(index) {
      this.$emit('select', index)
    },

    // 跟客服聊天
    chatWithStaff() {
      // Todo 客服人员ID暂时写死
      let chatMemberId = 3
      this.$api.checkIsFriend(chatMemberId).then((data) => {
        if (data === 1) {
            this.$emit('SelectSpecialChat', { chat_member_id: chatMemberId, chat_group_id: 0 })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-list-wrapper {
	width: 299px;
	//height: 640px;
	//height: 750px;
	position: relative;

	.customer-service {
		height: 50rpx;
		position: sticky;
		top: 0;
		z-index: 10;

		.nickname {
			position: absolute;
			top: 5px;
			left: 10px;
			height: 30px;
			line-height: 30px;
			padding: 5px;
			font-size: 12px;
			color: #666;
			font-weight: bold;
		}

		.staff {
			position: absolute;
			top: 5px;
			right: 10px;
			width: 30px;
			height: 30px;
			padding: 5px;
			cursor: pointer;
		}
	}

	.chat-list {
		height: 750px;
		overflow-y: auto;
		position: relative;
		.chat-item {
			width: 299px;
			height: 50px;
			padding: 10px 0;
			border-bottom: 1px solid #eee;
			position: relative;
			cursor: pointer;
			.avatar {
				position: absolute;
				top: 10px;
				left: 10px;
				width: 50px;
				height: 50px;
			}

			.name {
				width: 130px;
				line-height: 25px;
				font-size: 12px;
				color: #000000;
				padding-left: 65px;
				padding-right: 80px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.last-message-text {
				padding-left: 65px;
				padding-right: 20px;
				width: 200px;
				height: 25px;
				line-height: 25px;
				font-size: 10px;
				color: #999;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.last-message-time {
				position: absolute;
				right: 10px;
				top: 10px;
				font-size: 10px;
				color: #999;
				line-height: 25px;
			}

			.unread-count {
				position: absolute;
				right: 10px;
				bottom: 15px;
				font-size: 10px;
				line-height: 15px;
				padding: 0 5px;
				border-radius: 5px;
				color: #FFFFFF;
				background: #f56c6c;
			}
		}

		.chat-item-selected {
			background: #DDDDDD;
		}
	}

}

</style>
