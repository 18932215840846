<template>
	<div class="chat-detail">
		<div v-if="interactiveChatMember">
			<div class="name">
				{{ interactiveChatMember.name }}
				<div class="info" @click="showFriendInfo" v-if="interactiveChatMember.chat_member_id > 0">
					<el-image :src="require('@/assets/friend-info.png')"></el-image>
				</div>
			</div>
			<div ref="messageList" class="message-list" @scroll="handleScroll" :style="{ 'height': interactiveChatMember.can_chat ? '550px' : '750px' }">
				<div v-for="(item, index) in messageList" :key="index">
					<div v-if="interactiveChatMember.list_type === 'chat'" class="message-item">
						<div class="time" v-if="item.hidden_time === false">{{ item.time }}</div>
						<div class="box" :class="{ 'box-self': item.sender === 'self' }">
							<div class="avatar">
								<el-image style="width: 38px;height: 38px;border-radius: 5px;border: 1px solid #eee;" :src="item.avatar"></el-image>
							</div>
							<div class="content">
								<div class="text" v-if="item.type === 'text'" @dblclick="translate(index)">{{ item.text }}</div>
								<div class="pic" v-else-if="item.type === 'pic'">
									<el-image :src="item.pic"></el-image>
								</div>
								<div class="text" v-else-if="item.type === 'link'">{{ item.link_txt }}</div>
							</div>
						</div>
						<div class="box" :class="{ 'box-self': item.sender === 'self' }" style="margin-top: 10px;" v-if="item.translate_text">
							<div class="avatar"></div>
							<div class="content">
								<div class="text" style="background: #FFF;">{{ item.translate_text }}</div>
							</div>
						</div>
					</div>
					<div v-else-if="interactiveChatMember.list_type === 'article'" class="message-item">
						<div class="time">{{ item.time }}</div>
						<div class="box box-article">
							<div class="content">
								<div class="text" v-if="item.type === 'text'" @dblclick="translate(index)">{{ item.text }}</div>
								<div class="pic" v-else-if="item.type === 'pic'">
									<el-image :src="item.pic"></el-image>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div v-if="messageList.length === 0 && !interactiveChatMember.can_chat" style="margin-top: 100px;">
					<lxs-no-data content="暂无相关内容"></lxs-no-data>
				</div>
			</div>
			<div class="send-box" v-if="interactiveChatMember.can_chat">
				<div class="action-list">
					<div class="action-item">
						<el-image :src="require('@/assets/pic.png')" @click="selectPic"></el-image>
						<input ref="picUploadInput" type="file" @change="selectPicDone" style="display: none;"/>
					</div>
				</div>
				<!-- 输入区域 -->
				<div class="input-area" ref="inputDivRef" contenteditable="true" @keydown="handleInputDivKeydown($event)"></div>
			</div>
		</div>
		<friend-info-dialog ref="friendInfoDialog"></friend-info-dialog>
	</div>
</template>

<script>
import LxsNoData from "@/components/lxs-no-data";
import FriendInfoDialog from "@/components/FriendInfoDialog";

export default {
	name: 'ChatDetail',
	components: {LxsNoData, FriendInfoDialog},
	props: {
		loginChatMember: {
			type: Object,
			default: null,
		},
		interactiveChatMember: {
			type: Object,
			default: null,
		}
	},
	computed: {
		// 消息列表
		messageList() {
			let list = []
			if (this.interactiveChatMember && this.interactiveChatMember.chat_message_list) {
				let preTime = 0
				this.interactiveChatMember.chat_message_list.forEach((item) => {
					let currentTime = this.$utils.dateStrToTime(item.created_at)
					let temp = {
						id: item.id,
						name: item.from_chat_member_nickname,
						avatar: item.from_chat_member_avatar,
						type: item.type,
						text: '',
						text_language: item.content.text_language,
						translate_text: '',
						pic: '',
						time: item.time_txt,
						hidden_time: false,
						sender: this.interactiveChatMember.chat_member_id === item.from_chat_member_id ? 'other' : 'self',
					}
					if (item.type === 'text') {
						temp.text = item.content.text
						let key = 'translate_text_' + temp.text_language + '_' + this.language
						if (item.content.translate_chat_member_id_arr
							&& item.content.translate_chat_member_id_arr.indexOf(this.loginChatMember.id) !== -1
							&& item.content[key]) {
							// 用户翻译过，且是对应语言
							temp.translate_text = item.content[key]
						}
					} else if (item.type === 'pic') {
						temp.pic = item.content.pic
					} else if (item.type === 'link') {
						let linkData = JSON.parse(item.content.link_data)
						temp.link_txt = '[链接]' + (linkData.name ? linkData.name : '')
					}
					if (item.to_chat_member_id > 0) {
						// 若当前时间跟上一个时间相差不超过3分钟，就不需要显示
						temp.hidden_time = currentTime - preTime < 3 * 60 * 1000
					}
					list.push(temp)
					preTime = currentTime
				})
			}
			return list
		},
		// 语言
		language() {
			let language = navigator.language || navigator.userLanguage
			if (language === 'zh-CN') {
				language = 'zh'
			}
			return language
		},
	},
	data() {
		return {
			willSendText: '',
			imageBase64Str: '',
			width: 100,
			height: 100,
		}
	},
	mounted() {
		const that = this;
		document.body.addEventListener('paste', function (e) {
			// 获取当前输入框内的文字
			// 读取图片
			let items = e.clipboardData && e.clipboardData.items;
			let file = null;
			if (items && items.length) {
				// 检索剪切板items
				for (let i = 0; i < items.length; i++) {
					if (items[i].type.indexOf('image') !== -1) {
						file = items[i].getAsFile();
						break;
					}
				}
			}
			if (!file) return;
			that.convertImageToBase64(file, 'paste')
		});
	},
	methods: {
		// 清除文本
		clearText() {
			this.imageBase64Str = ''
			if (this.$refs.inputDivRef) {
				this.$refs.inputDivRef.innerHTML = "";
			}
		},
		// 发送文本消息
		sendTextChatMessage() {
			if (this.interactiveChatMember) {
				if (this.willSendText.length === 0) {
					this.$message.error('消息内容不能为空')
					return
				}
				this.$emit('sendChatMessage', {
					to_chat_member_id: this.interactiveChatMember.chat_member_id,
					to_chat_group_id: this.interactiveChatMember.chat_group_id,
					message_type: 'text',
					message_content: this.willSendText
				})
			}
		},

		// 发送图片消息
		sendPicChatMessage() {
			if (this.interactiveChatMember) {
				this.$emit('sendChatMessage', {
					to_chat_member_id: this.interactiveChatMember.chat_member_id,
					to_chat_group_id: this.interactiveChatMember.chat_group_id,
					message_type: 'pic',
					message_content: this.imageBase64Str
				})
				setTimeout(() => {
					this.imageBase64Str = ''
				}, 100)
			}
		},

		// 处理滚动
		handleScroll(event) {
			const div = this.$refs.messageList
			let maxScrollTop = div.scrollHeight - div.clientHeight

			if (event.target.scrollTop === 0) {
				// 滚动到顶部
				this.$emit('ScrollToTop')
			} else if (event.target.scrollTop === maxScrollTop) {
				// 滚动到底部
				this.$emit('ScrollToBottom')
			}
		},

		// 查看好友信息
		showFriendInfo() {
			this.$refs.friendInfoDialog.open(this.interactiveChatMember)
		},

		// 滚动到底部
		scrollToMessageListBottom() {
			this.$nextTick(() => {
				const div = this.$refs.messageList
				if (div) {
					div.scrollTop = div.scrollHeight
				}
			})
		},

		// 保持滚动内容
		keepScrollContent(oldScrollHeight) {
			this.$nextTick(() => {
				const div = this.$refs.messageList
				div.scrollTop = div.scrollHeight - oldScrollHeight
			})
		},

		// 翻译
		translate(index) {
			if (this.language === this.messageList[index].text_language) {
				this.$message.warning('无需翻译')
				return
			}
			this.$emit('translate', {
				interactive_chat_member: this.interactiveChatMember,
				chat_message_id: this.messageList[index].id,
			})
		},

		// 选择图片
		selectPic() {
			this.$refs.picUploadInput.click()
		},

		// 选择完图片
		selectPicDone(event) {
			const file = event.target.files[0];
			if (!file) return;
			let allowTypeArr = ['image/png', 'image/jpeg']
			if (allowTypeArr.indexOf(file.type) === -1) {
				this.$message.warning('该文件类型不支持，目前只支持png、jpeg图片')
				return
			}
			// let allowSize = 1024000
			// if (file.size > allowSize) {
			// 	this.$message.warning('该文件大小超过限制')
			// 	return
			// }
			this.convertImageToBase64(file, 'select')
		},
		// 转为base64图并处理
		convertImageToBase64(file, action) {
			// 预览图片
			const reader = new FileReader();
			const that = this;
			reader.onload = function (event) {
				// 图片内容
				const imgContent = event.target.result;
				const imgId = 'send-preview-img'
				// 销毁img标签
				let img = document.getElementById(imgId);
				if (img) {
					img.remove();
					img = null
				}
				if (!img) {
					// 创建img标签
					img = document.createElement('img');//创建一个img
					img.setAttribute("id", imgId);
					// 获取当前base64图片信息，计算当前图片宽高以及压缩比例
					let imgObj = new Image();
					let imgWidth = "";
					let imgHeight = "";
					let scale = 1;
					imgObj.src = imgContent;
					imgObj.onload = function () {
						// 计算img宽高
						if (that.width < 400) {
							imgWidth = that.width;
							imgHeight = that.height;
						} else {
							// 输入框图片显示缩小10倍
							imgWidth = that.width / 10;
							imgHeight = that.height / 10;
							// 图片宽度大于1920，图片压缩5倍
							if (that.width > 1920) {
								// 真实比例缩小5倍
								scale = 5;
							}
						}
						// 设置可编辑div中图片宽高
						img.width = imgWidth;
						img.height = imgHeight;

						// 压缩图片，渲染页面
						that.compressPic(imgContent, scale, function (newBlob, newBase) {
							img.src = newBase; //设置链接
							// 发送图片
							that.imageBase64Str = newBase
							if (action === 'select') {
								that.$refs.inputDivRef.appendChild(img);
							}
						});
					};
				}
			};
			reader.readAsDataURL(file);
		},
		// 参数: base64地址,压缩比例，回调函数(返回压缩后图片的blob和base64)
		compressPic(base64, scale, callback) {
			const mediaType = 'image/png'
			const that = this;
			let _img = new Image();
			_img.src = base64;
			_img.onload = function () {
				let _canvas = document.createElement("canvas");
				const _context = _canvas.getContext("2d");

				let w = this.width / scale;
				let h = this.height / scale;

				// 设置画布尺寸为图片大小
				_canvas.width = w;
				_canvas.height = h;

				// 填充白色背景（防止透明背景变黑）
				_context.fillStyle = "white";
				_context.fillRect(0, 0, w, h);

				// 绘制图片
				_context.drawImage(_img, 0, 0);
				// png 否则背景色是黑色
				let base64 = _canvas.toDataURL(mediaType);
				// 当canvas对象的原型中没有toBlob方法的时候，手动添加该方法
				if (!HTMLCanvasElement.prototype.toBlob) {
					Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
						value: function (callback, type, quality) {
							let binStr = atob(this.toDataURL(type, quality).split(',')[1]),
								len = binStr.length,
								arr = new Uint8Array(len);
							for (let i = 0; i < len; i++) {
								arr[i] = binStr.charCodeAt(i);
							}
							callback(new Blob([arr], {type: type || mediaType}));
						}
					});
				} else {
					_canvas.toBlob(function (blob) {
						if (blob.size > 1024 * 1024) {
							that.compressPic(base64, scale, callback);
						} else {
							callback(blob, base64);
						}
					}, mediaType);
				}
			}
		},
		handleInputDivKeydown(event) {
			if (event.keyCode === 13) {
				let el = this.$refs.inputDivRef
				this.willSendText = el.innerText
				if (!event.ctrlKey) {
					event.preventDefault();
					if (this.imageBase64Str) {
						this.sendPicChatMessage()
					}
					if (this.willSendText) {
						this.sendTextChatMessage();
					}
					this.$refs.inputDivRef.innerHTML = "";
				}
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.chat-detail {
	width: 700px;
	height: 800px;
	background: #F3F3F3;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	overflow: hidden;

	.name {
		width: 700px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		font-weight: bold;
		border-bottom: 1px solid #DFDFDF;
		position: relative;

		.info {
			position: absolute;
			right: 13px;
			top: 13px;
			width: 24px;
			height: 24px;
			cursor: pointer;
		}
	}

	.message-list {
		width: 700px;
		height: 550px;
		overflow: auto;

		&::-webkit-scrollbar {
			/* 隐藏滚动条，但依旧具备可以滚动的功能 */
			display: none;
			width: 0;
			height: 0;
			background-color: transparent;
		}

		.message-item {
			width: 680px;
			padding: 10px;
			margin: 2px 0;
		}

		.message-item .time {
			text-align: center;
			font-size: 12px;
			margin-bottom: 5px;
			color: #AAAAAA;
		}

		.message-item .box {
			width: 680px;
			min-height: 40px;
			position: relative;
			display: flex;
			justify-content: flex-start;
			flex-direction: row;
		}

		.message-item .box .avatar {
			width: 40px;
			height: 40px;
		}

		.message-item .box .content {
			width: 520px;
			margin: 0 10px;
			display: flex;
			justify-content: flex-start;
		}

		.message-item .box .content .text {
			position: relative;
			width: auto;
			height: auto;
			display: inline-block;
			background: #FFFFFF;
			color: #11170C;
			padding: 10px;
			line-height: 20px;
			border-radius: 5px;
			font-size: 12px;
		}

		.message-item .box .translate-content {
			position: absolute;
			top: 50px;
			width: 520px;
			margin: 0 10px;
			display: flex;
			justify-content: flex-start;
		}

		.message-item .box .translate-content .text {
			width: auto;
			height: auto;
			display: inline-block;
			background: #FFFFFF;
			color: #11170C;
			padding: 10px;
			line-height: 20px;
			border-radius: 5px;
			font-size: 12px;
		}

		.message-item .box .content .pic {
			width: auto;
			height: auto;
			padding: 10px;
			border-radius: 5px;
			display: inline-block;
			border: 1px solid #FFFFFF;
		}

		.message-item .box-self {
			flex-direction: row-reverse;
		}

		.message-item .box-self .content {
			justify-content: flex-end;
		}

		.message-item .box-self .content .text {
			background: #A9EA7A;
		}

		.message-item .box-self .content .pic {
			border: 1px solid #A9EA7A;
		}

		.message-item .box-article {
			justify-content: center;
		}

		.message-item .box-article .content {
			width: 580px;
			justify-content: center;
		}
	}

	// 800-50-550-7*2
	.send-box {
		height: 186px;
		padding: 7px 0;
		border-top: 1px solid #DFDFDF;
		border-bottom-right-radius: 10px;
		background: #FFFFFF;
		overflow-y: hidden;

		.action-list {
			height: 30px;
			display: flex;
			align-items: center;

			.action-item {
				width: 30px;
				height: 30px;
				margin: 0 10px;
				cursor: pointer;
			}
		}
		.input-area {
			padding: 5px 10px;
			// 186-30-5*2
			height: 146px;
			font-size: 14px;
			line-height: 1.5;
			color: #333333;
			border-bottom-right-radius: 10px;
			overflow-y: auto;
			display: grid;

			/* 取消contenteditable激活时的默认样式 */
			&:focus {
				outline: none; /* 去除激活时的轮廓 */
				box-shadow: none; /* 去除激活时的阴影效果 */
			}

			&:empty:before {
				content: "请输入聊天内容，按Ctrl+Enter换行，按Enter键发送";
				font-size: 14px;
				line-height: 1.5;
				color: #c0c4cc;
			}
		}
	}
}
</style>
